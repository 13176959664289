@import "../../assets/scss/media";
@import "../../assets/scss/mixin";
body {
  background-color: #f5f5f5;
}

.advertise {
  text-align: center;
  border: 1px solid #ccc;
  padding: 2rem;
  margin-bottom: 2rem;
}

.allWrapper {
  margin: 2rem 0;
  border-radius: 1rem;

  .profile-tabs {
    width: 100%;
    // background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    ul {
      display: flex;
      padding: 0;
      margin: 0;
      list-style: none;
      background: #1d7336;
      border-radius: 10px 10px 0 0;

      li {
        flex-grow: 1;
        text-align: center;
        font-size: 2rem;

        label {
          display: block;
          padding: 1rem 2rem;
          color: #fff;
          cursor: pointer;
          transition: background-color 0.3s, color 0.3s;

          &:hover,
          &.active {
            background-color: #0a2e0c;
            color: #fff;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
          }
        }
        &.active label {
          background-color: #0a2e0c;
          color: #fff;
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;
        }
      }
    }
  }

  .content {
    display: block;
    padding: 20px;
    // background: #f8f9fa;
    background-color: #fff;
    border-top: 1px solid #eee;
    border-radius: 0 0 10px 10px;

    .claimScore-data {
      padding: 2rem;
      .claim-list {
        p {
          font-size: 2rem;
          font-weight: 500;
          text-align: center;
          color: black;
        }
      }
      .claim-button {
        button {
          padding: 0.8rem 1.5rem;
          font-size: 1.4rem;
          color: #fff;
          background-color: #1d7336;
          border: none;
          border-radius: 8px;
          cursor: pointer;
          transition: background-color 0.3s ease-in-out;
          &:hover {
            background-color: #0a2e0c;
          }
          &:active {
            transform: scale(0.98);
          }
        }
      }
    }

    .inner-tabs {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: fit-content;
      gap: 15.5rem;
      margin: auto;
      margin-bottom: 3rem;
      border-bottom: 2px solid rgba(229, 229, 229);
      .tab-btn {
        font-size: 2rem;
        font-weight: 500;
        padding: 1.5rem;
        color: black;
        cursor: pointer;
        &:hover {
          color: #1d7336;
        }
        &.active {
          border-bottom: 4px solid #1d7336;
          border-top: none;
          // border-radius: 4px;
          border-left: none;
          color: #1d7336;
          // transition: all 0.3s ease;
        }
      }
    }

    .form-container {
      max-width: 900px;
      margin: 2rem auto;
      padding: 2rem;
      label {
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
        display: block;
        color: #333;
      }
      input,
      select {
        width: 100%;
        padding: 0.8rem 1rem;
        margin-bottom: 1rem;
        border: 1px solid #ddd;
        border-radius: 8px;
        font-size: 1.4rem;
        color: #333;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
        transition: border-color 0.3s ease-in-out;
        &:focus {
          border-color: #1d7336;
          outline: none;
          box-shadow: 0 2px 6px rgba(0, 123, 255, 0.3);
        }
      }
      .error {
        color: #e63946;
        font-size: 1.2rem;
        margin-top: -0.5rem;
        margin-bottom: 1rem;
      }
      .btn-style {
        display: flex;
        justify-content: space-between;
      }
      button {
        padding: 0.8rem 1.5rem;
        font-size: 1.4rem;
        color: #fff;
        background-color: #1d7336;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
        &:hover {
          background-color: #0a2e0c;
        }
        &:active {
          transform: scale(0.98);
        }
      }
      div {
        margin-bottom: 1.5rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .strike-rate-display {
        input {
          background-color: #e9ecef;
          color: #495057;
          font-weight: bold;
          text-align: center;
        }
      }
      .add-btn {
        margin-bottom: -4rem;
      }
    }

    .stats-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1rem;
      padding: 1.5rem;
      border-radius: 10px;
      .stat-box {
        background: #f8f9fa;
        border: 1px solid #ccc;
        border-radius: 8px;
        padding: 1rem 1.5rem;
        text-align: center;
        min-width: 120px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
        position: relative;
        .stat-number {
          display: block;
          font-size: 2rem;
          font-weight: bold;
          color: #333;
        }
        .stat-label {
          display: block;
          font-size: 1.5rem;
          color: #555;
          margin-top: 0.5rem;
        }
      }
    }

    .table-container {
      margin: 20px auto;
      font-family: Arial, sans-serif;
      border-radius: 8px;
      overflow: hidden;
      .info-table {
        width: 100%;
        border-collapse: collapse;
        font-size: 1.5rem;
        color: #333;
        .info-table th,
        .info-table td {
          padding: 12px 16px;
          text-align: left;
        }
        .info-table th {
          color: white;
          text-transform: uppercase;
        }
        .table-row th,
        .table-row td {
          padding: 1rem;
        }
        .table-row:nth-child(even) {
          background-color: #f9f9f9;
        }
        .table-row:hover {
          background-color: #f1f1f1;
          transition: background-color 0.3s ease-in-out;
        }
        .table-data {
          border-bottom: 1px solid #ddd;
        }
        .table-header {
          font-weight: bold;
          border-bottom: 1px solid #ddd;
        }
      }
    }
  }
  .link {
    font-size: 1.5rem;
    color: #1d7336;
    &:hover {
      color: #0a2e0c;
    }
  }
}

.verification-status {
  font-family: Arial, sans-serif;
  padding: 20px;
}

.status-table {
  width: 100%;
  border-collapse: collapse;
}

.status-table th,
.status-table td {
  // border: 1px solid #ddd;
  padding: 2rem;
  text-align: left;
  font-size: 1.5rem;
}

.status-table th {
  background-color: #f4f4f4;
  font-size: 1.8rem;
  font-weight: bold;
}

.status-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.status-table tr:hover {
  background-color: #f1f1f1;
}

.status-table .status {
  font-weight: bold;
  text-transform: capitalize;
}
