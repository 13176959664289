@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@import "../scss/media";
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
}
body {
  font-family: "Work Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  @media (max-width: 400px) {
    width: 100%;
  }
}
ul,
ol {
  padding: 0;
  margin-bottom: 0;
  li {
    list-style: none;
  }
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
button {
  padding: 0;
  margin-bottom: 0;
}
button {
  border: 0;
  background-color: transparent;
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
p,
span,
strong,
b,
a,
li,
input {
  padding: 0;
  margin-bottom: 0;
}

.SmallDeviceFeature-cricket{
  display: none;
  @include md-mobile{
    display: block;
  }
}
.desktopDeviceFeature-cricket{
  @include md-mobile{
    display: none;
  }
}
.container {
// @include x-767-tablet {
//   max-width: 660px;
// }
// @include md-mobile {
//   max-width: 450px;
// }
@include mid-991-tab {
  max-width: 860px
}
}

.toast-container {
  font-family: 'work Sans';
  font-size: 1.8rem;
  padding: 20px;
}


