/* Upload Area */
.upload-area {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border: 2px dashed #ccc;
  border-radius: 22px;
}

.upload-area__header {
  text-align: center;
}

.upload-area__title {
  font-size: 1.8rem;
  font-weight: 600;
  //   margin-bottom: 10px;
  color: #fff;
}

.upload-area__paragraph {
  font-size: 1rem;
  color: #fff;
}

.upload-area__tooltip {
  //   font-weight: bold;
  color: #fff;
}

.upload-area__tooltip-data {
  font-size: 12px;
  color: #fff;
}

/* Drop Zone */
.upload-area__drop-zoon {
  width: 100%;
  //   height: 200px;
  //   border: 2px dashed #007bff;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.drop-zoon--over {
  background-color: #e6f7ff;
}

.drop-zoon__icon {
  font-size: 40px;
  color: #fff;
}

.drop-zoon__paragraph {
  font-size: 1.3rem;
  color: #fff;
  //   margin-top: 10px;
}

.drop-zoon__loading-text {
  font-size: 14px;
  color: #fff;
  margin-top: 10px;
}

.drop-zoon__preview-image {
  max-width: 100%;
  max-height: 100px;
  margin-top: 1rem;
}

.drop-zoon__file-input {
  display: none;
}

/* File Details */
.upload-area__file-details {
  margin-top: 20px;
  //   padding: 10px;
  //   border-top: 1px solid #ccc;
}

.file-details__title {
  font-size: 1.5rem;
  //   margin-bottom: 10px;
  color: #fff;
}

.uploaded-file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
}

.uploaded-file__icon-container {
  display: flex;
  align-items: center;
}

.uploaded-file__icon {
  font-size: 24px;
  color: #fff;
  //   margin-right: 10px;
}

.uploaded-file__icon-text {
  font-size: 1.3rem;
  color: #fff;
}

.uploaded-file__info {
  display: flex;
  flex-direction: column;
}

.uploaded-file__name {
  font-size: 1.3rem;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
}

.uploaded-file__counter {
  font-size: 1.3rem;
  color: #fff3;
  margin-top: 5px;
}

.drop-zoon__progress {
  width: 100%;
  height: 4px;
  background-color: #e0e0e0;
  border-radius: 2px;
  margin-top: 1rem;
  overflow: hidden;
  position: relative;
}

.drop-zoon__progress-bar {
  width: 0;
  height: 100%;
  background-color: #fff;
  transition: width 0.2s ease;
}

.uploaded-file__size {
  font-size: 1rem;
  color: #fff;
}

.uploaded-file__progress {
  font-size: 1rem;
  color: #fff;
}

.drop-zoon__progress {
  width: 100%;
  height: 4px;
  background-color: green;
  border-radius: 2px;
  margin-top: 1rem;
  overflow: hidden;
  position: relative;
}

.drop-zoon__progress-bar {
  width: 0;
  height: 100%;
  background-color: #4caf50;
  transition: width 0.2s ease;
}

.uploaded-file__delete-button {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #fff;
  transition: color 0.3s;
}

.uploaded-file__delete-button:hover {
  color: #e91212;
}

.drop-zoon--Uploaded {
  position: relative;
}

.drop-zoon__progress {
  width: 100%;
  height: 4px;
  //   background-color: #e0e0e0;
  border-radius: 2px;
  margin-top: 1rem;
  overflow: hidden;
}

.drop-zoon__progress-bar {
  width: 0;
  height: 100%;
  background-color: #fff;
  transition: width 0.2s ease;
}

.uploaded-file__details {
  display: flex;
  justify-content: space-between;
  width: 265px;
}
