@import "../../../assets/scss/media";
@import "../../../assets/scss/mixin";
.letestBlog-page {
  background-color: #d6edff;
  border-radius: 5px;
  height: auto;

  .latestBlog {
    display: flex;
    justify-content: center;
    padding: 2rem 0;
  }
  h6 {
    @include subTitleTypo;
  }

  .news-section {
    overflow: hidden;
    .news-card {
      img {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        width: 100%;
        max-height: 440px;
        object-fit: cover;
      }

      .newsCard-content {
        background-color: #ffffff;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        .top-text {
          padding: 1.5rem 2rem;
          h4 {
            margin-bottom: 1rem;
            a {
              font-family: "Work Sans";
              font-size: 2.6rem;
              font-weight: 600;
              color: #0c495b;
              cursor: pointer;
            }
          }
          p,
          a {
            font-family: "Work Sans";
            font-size: 2rem;
            color: #6c6c6c;
            cursor: pointer;
          }
        }
        .bottom-text {
          border-top: 0.8px solid #89abb8;
          padding: 1rem 2rem;
          small {
            font-family: "Work Sans";
            font-size: 1.4rem;
            color: #6c6c6c;
          }
        }
      }
    }
  }

  .news-sidebar {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .div2,
    .div3,
    .div4 {
      background-image: url("../../../../public/images/blogImg1.svg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      height: 200px;
      display: flex;
      align-items: flex-end;
      width: 100%;
      border-radius: 5px;

      a {
        color: #ffffff;
        z-index: 999;
        font-family: "Work Sans";
        font-size: 1.8rem;
        font-weight: 600;
        padding: 1rem;
        width: 340px;
        cursor: pointer;
      }
    }

    .card {
      position: relative;
      overflow: hidden;
      border-radius: 5px;
    }
  }

  .readmore-btn {
    display: flex;
    justify-content: center;

    .regButton {
      margin: 3rem;
      background: #1d7336;
    }

    span {
      color: #ffffff;
    }
  }

  .parent {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-column-gap: 15px;
    // grid-row-gap: 76px;
    @include x-600-tablet {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 1fr);
    }
  }

  .div1 {
    grid-area: 1 / 1 / 4 / 2;
  }
  .div2 {
    grid-area: 1 / 2 / 2 / 3;
  }
  .div3 {
    grid-area: 2 / 2 / 3 / 3;
  }
  .div4 {
    grid-area: 3 / 2 / 4 / 3;
  }
}
