@import "../../../assets/scss/media";
.mentioned-section { 
    // width: 100%;
    // background: #D4FFE8;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1.5rem;
    overflow: hidden;
  
    p {
      font-family: 'Work Sans';
      font-size: 2rem;
      font-weight: 500;
      color: #416D3D;
      margin-bottom: 20px;
    }
    @include mobile {
      height: 100%;
      padding: 1.5rem;
    }
  }

.image-wrapper {
    padding: 0 30px;
}

.image-wrapper img {
    // width: 80%;
}