@import "../../assets/scss/media";

body {
  background-color: #f5f5f5;
}

.dashboard {
  padding: 7rem 0 5rem;
  @include x-767-tablet {
    padding: 4rem 0 3rem;
  }
}

.tab-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 3rem;
}

// .tabs-container {
//   background-color: #f5f5f5;
// }

.tabs {
  height: 44px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  border-radius: 22px;
  // gap: 2.5rem;
  background-color: #fff;
  margin: auto;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #1D7336;
  @include x-tablet {
    width: 98%;
  }

  &::-webkit-scrollbar {
    height: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #416D3D;
    border-radius: 10px;
  }

  .tab-main-box {
    margin-bottom: 5rem;
  }

  button {
    flex: 0 0 auto;
    min-width: 100px;
    background-color: #fff;
    color: #8c8c8c;
    border: none;
    cursor: pointer;
    text-align: center;
    font-size: 1.8rem;
    font-family: 'Work Sans';
    border-radius: 22px;
    margin-right: 1rem;
    @include mobile {
      font-size: 1.6rem;
    }

    &.active {
      background-color: #1D7336;
      color: white;
      border-radius: 22px;
      width: 135px;
    }
  }
}

.tab-content {
  padding: 20px;
  margin-top: -1px;

  div {
    @include mobile{
      display: none;
    }

    &.active {
      display: block;
    }
  }
}

.dropdown-city {
  font-size: 20px;
  font-weight: normal;
  color: #8c8c8c;
  font-family: 'Work Sans';
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 3rem 1rem;
  @include x-tablet {
    font-size: 1.8rem;
  }
  @include mobile {
    font-size: 1.8rem;
  }

  select {
    font-size: 1.8rem;
    color: #8c8c8c;
    width: 145px;
    height: 42px;
    border-color: #8c8c8c;
    border-radius: 21px;
    color: #1D7336;
    @include x-tablet {
      width: 120px;
      height: 35px;
      font-size: 1.8rem;
    }
  }

  option {
    font-size: 1.8rem;
    color: #8c8c8c;
    font-family: 'Work Sans';
    text-align: center;
    color: #1D7336;
    @include x-tablet {
      font-size: 1.8rem;
    }
    @include mobile {
      font-size: 1.5rem;
    }
  }

  button {
    background: none;
    border: none;
  }
}
