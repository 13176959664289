.board-content {
  display: flex;
  justify-content:space-between;
  align-items:center;
   gap:1rem;
}

.name-table {
  // display: flex;
  // flex-direction: column;
  // gap: 1rem;
}
.profile-photo {
  width:100px;
  height:100px;
  border: 1px solid rgba(0, 0, 0, 0.788);
  border-radius:50%;
  display: flex;
  justify-content:space-between;
  align-items: center;
  // gap:5rem;
  margin-bottom: 1rem;
}

.table-responsive {
  overflow-x: auto;
}

.inning-data {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.leaderboard {
  width: auto;
  border-collapse: collapse;
  margin-bottom: 2rem;
  background: #fff;
  overflow: hidden;
  border-radius: 5px;

  .column-headers {
    th {
      width:20rem;
      font-family: "Work Sans";
      font-size: 1.6rem;
      text-align: center;
      padding: 8px;
      border: 1px solid #ececec;
      background-color: #4c7737;
      color: white;
      &:first-child {
        // width: 37rem;
        text-align: left;
        padding-left: 2rem;
      }
    }
  }

  tbody {
    td {
      font-family: "Work Sans";
      text-align: center;
      padding: 18px 22px;
      border: 1px solid #ececec;
      font-size: 1.6rem;
      color: #6c6c6c;

      &:first-child {
        color: #1d7336;
        text-align: left;
      }
    }
  }
}

.leaderboard-dropdown {
  display: flex;
  justify-content: space-between;
  padding: 2rem 0;
}

.dropdown {
  font-size: 20px;
  font-weight: normal;
  color: #8c8c8c;
  font-family: 'Work Sans';
  display: flex;
  gap: 1rem;

  select {
    font-size: 20px;
    color: #8c8c8c;
    width: 145px;
    height: 42px;
    border-color: #8c8c8c;
    border-radius: 22px;
    color: #1D7336;
  }

  option {
    font-size: 1.8rem;
    color: #8c8c8c;
    font-family: 'Work Sans';
    text-align: left;
    color: #1D7336;
  }
}

.profile-photo_name{
  width:100px;
}