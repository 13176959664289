@import "../../assets/scss/media.scss";
body {
  background-color: #f5f5f5;
}
.matchStat-wrapper {
  padding: 4rem 0 3rem;
.matchStat-leftPart {
  .heading {
    margin-bottom: 2rem;
    p {
      font-family: "Work Sans";
      font-size: 1.8rem;
      font-weight: 500;
      color: #1e1e1e;
      @include mobile {
        font-size: 1.5rem;
        margin-bottom: 2rem;
      }
    }
  }

  // .combine-Part {
  //   display: grid;
  //   grid-template-columns: repeat(2, auto);
  //   grid-template-rows: 1fr;
  //   grid-column-gap: 15px;
  //   grid-row-gap: 0px;
  //   @include d-desktop {
  //     display: flex;
  //     grid-template-columns: repeat(2, auto);
  //     grid-gap: 0rem;
  //     grid-template-columns: 1fr;
  //     grid-template-rows: repeat(2, 1fr);
  //     flex-direction: column;
  //   }
  // }
}
}

