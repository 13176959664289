@import "../../../assets/scss/media";
#banner {
  position: relative;
  &.homeBanner {
    // background-image: url(../../../../public/images/home_banner.png);
    background-image: url(../../../../public/images/full-banner.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    width: 100%;
    height: 65.5rem;
    @include d-1366-desktop {
      background-image: url(../../../../public/images/full-banner.png);
    }
    @include d-desktop {
      height: 35rem;
    }
  }
  .midContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    .banner-heading {
      font-size: 7.2rem;
      font-weight: bold;
      color: #fff;
      line-height: 0.8;
      @include d-desktop {
        font-size: 5.2rem;
      }
      @include mobile {
        font-size: 3.6rem;
      }
    }
    p {
      font-size: 1.8rem;
      color: #d7d2d2;
      font-weight: 500;
      @include d-desktop {
        font-size: 1.6rem;
      }
      @include mobile {
        font-size: 1.2rem;
      }
    }
    .storeIconWrap {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      @include d-desktop {
        width: 45%;
      }
      a {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

// Link header and footer to the inner pages
.allMatchPages {
  #banner {
    &.homeBanner {
      background-image: url(../../../../public/images/innerPgFullImg.png);
      height: 35rem;
      // @include d-1366-desktop {
      //   background-image: url(../../../../public/images/innerPgFullImg.png);
      // }
    }
    .midContainer {
      h2 {
        font-size: 3rem;
      }
      p {
        display: none;
      }
      .storeIconWrap {
        display: none;
      }
      .regButton {
        display: none;
      }
    }
  }
}
.tournamentDashPage {
  #banner {
    &.homeBanner {
      background-image: url(../../../../public/images/innerPgFullImg.png);
      height: 35rem;
      // @include d-1366-desktop {
      //   background-image: url(../../../../public/images/innerPgFullImg.png);
      // }
    }
    .midContainer {
      p {
        display: none;
      }
      .storeIconWrap {
        display: none;
      }
      .regButton {
        display: none;
      }
    }
  }
}
.domesticTournamnet {
  #banner {
    &.homeBanner {
      background-image: url(../../../../public/images/innerPgFullImg.png);
      height: 35rem;
      // @include d-1366-desktop {
      //   background-image: url(../../../../public/images/innerPgFullImg.png);
      // }
    }
    .midContainer {
      p {
        display: none;
      }
      .storeIconWrap {
        display: none;
      }
      .regButton {
        display: none;
      }
    }
  }
}

.matchStat {
  #banner {
    &.homeBanner {
      background-image: url(../../../../public/images/innerPgFullImg.png);
      height: 35rem;
      @include mobile {
        height: 30rem;
      }
    }
    .midContainer {
      p {
        display: none;
      }
      .storeIconWrap {
        display: none;
      }
      .regButton {
        display: none;
      }
    }
  }
}

