@import "../../../assets/scss/media";

footer {
  // border-image-source: linear-gradient(90deg, #0C495B 0%, #1D7336 100%);
  // background: linear-gradient(260.04deg, #3C7749 1.87%, #125671 96.08%);
  /* background: url('images/footerBgImg.png') no-repeat center center; */
  background-image: url(../../../../public/images/footerImage.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    width: 100%;
    // height: 65.5rem;

  .footer-container {
    padding: 3rem;
  }
      
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 203px;
      height: 52px;
    }
  }

  .followUs-container {
    display: flex;
    margin-top: 15px;

    p {
      font-family: 'Work Sans';
      font-size: 18px;
      font-weight: 500;
      line-height: 21.11px;
      color: #B7B7B7;
    }

    .social-icons {
      display: flex;
      gap: 15px;
      margin-left: 5px;

      .circle-box {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid #fff;
        display: flex;
        justify-content: center;
        align-items: center;

        .twitterIcon,
        .facebookIcon,
        .linkdinIcon,
        .youtubeIcon,
        .instagramIcon {
          font-size: 12px;
          fill: #fff;
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  .storeIconWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 1.5rem;
    @include d-desktop {
      // width: 45%;
      margin: auto;
      margin-top: 1.5rem;
    }
    @include mid-991-tab {
      margin: auto;
      margin-top: 1.5rem;
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .footer-links {
    display: flex;
    justify-content: center;
    gap: 4.5rem;
    flex-wrap: wrap;
    margin-top: 3rem;
    @include mobile {
      gap: 2.5rem;
    }
        
    a {
      text-decoration: none;
      font-family: 'Work Sans';
      font-size: 18px;
      font-weight: 500;
      color: #FFFFFF;
      @include mobile {
        font-size: 1.4rem;
      }
    }
  }

  .footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background: #19191A;
    @include mobile {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      background: #19191A;
    }

    p {
      font-family: 'Work Sans';
      font-size: 1.5rem;
      color: #B7B7B7;
      @include mobile {
        font-size: 1.4rem;
      }
      @include xssm_340_mobile {
        font-size: 1.2rem;
      }
    }
  }
}
