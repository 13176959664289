@import '../../../assets/scss/media';
.image-container {
  position: relative;
  background-color: #ffffff;
  border-radius: 5px;
  margin-bottom: 2rem;
  padding: 10px;

  img {
    border-radius: 5px;
    width: 100%;
    margin: auto;
  }
}

.graph-img {
  display: inline-block;
  position: relative;
  background-color: #ffffff;
  padding: 10px;
  margin-bottom: 2rem;
  border-radius: 5px;
  width: 100%;
  img {
    border-radius: 5px;
  }
}

.match-details-card {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  font-family: Arial, sans-serif;
  margin-bottom: 2rem;

  .match-details-header {
    text-align: left;
    padding: 15px;
    border-bottom: 1px solid #ececec;
    font-family: "Work Sans";
    font-size: 1.6rem;
    font-weight: 500;
    color: #1e1e1e;
  }

  .match-detail {
    display: flex;
    justify-content: space-between;
    padding: 12px 15px;
    border-bottom: 1px solid #ececec;

    &:last-child {
      border-bottom: none;
    }

    .detail-label {
      font-family: "Work Sans";
      font-size: 1.6rem;
      color: #1e1e1e;
    }

    .detail-value {
      font-family: "Work Sans";
      font-size: 1.6rem;
      color: #6c6c6c;
    }
  }
}

.match-notes {
  padding: 12px 15px;
  border-bottom: 1px solid #ececec;

  .detail-value {
    font-family: "Work Sans";
    font-size: 1.6rem;
    color: #6c6c6c;
  }
}
