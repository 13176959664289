.dropdown-menu li {
    font-size: 18px;
    font-family: Work Sans;
    color: #FFFFFF;
}

.dropdown-menu li :hover {
    color: #416D3D;
}

.collapse {
    display: none; // Hide by default
  }
  
  .show {
    display: block; // Show when menuOpen is true
  }
  