@mixin teamLogo1 {
  width: 42px;
  height: 42px;
  background: #ffffff;
  border: 0.85px solid #4764da;
  border-radius: 50%;
}

@mixin subTitleTypo {
  font-family: "Work Sans";
  font-size: 3rem;
  font-weight: 600;
  color: #0c495b;
}

@mixin formHeadingTypo {
  font-family: "Work Sans";
  font-size: 3rem;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
}

@mixin formLabelTypo {
  font-family: "Work Sans";
  font-size: 1.8rem;
  font-weight: 500;
  color: #fff;
  margin-bottom: 5px;
}

@mixin inputTypo {
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 400;
  background-color: #e8f0fe;
  padding: 1rem;
  height: 45px;
}

@mixin formPlaceholderTypo {
  font-family: "Work Sans";
  font-size: 1.5rem;
  color: #a7a3a3;
}

@mixin formErrorMsgTypo {
  font-size: 1.4rem;
  color: #ffffff;
  background: red;
  padding: 0.2rem 1rem;
  border-radius: 0.5rem;
  margin-top: 0.8rem;
  width: fit-content;
  font-weight: 400;
}

@mixin formButtonTypo {
  background-color: #075d9a;
  color: #ffffff;
  border: none;
  border-radius: 0.5rem;
  font-family: "Work Sans";
  font-size: 2rem;
  font-weight: 500;
  cursor: pointer;
  height: 45px;
}

@mixin anchotTagTypo {
  font-family: "Work Sans";
  font-size: 1.5rem;
  color: #fff;
}
