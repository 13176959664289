@import '../../assets/scss/media.scss';

.matches {
  margin: 1rem 0;
  @include x-767-tablet {
    margin: 1rem 2rem;
  }
  // @include mid-991-tab {
  //   margin: 1rem 2rem;
  // }
}

.header-div {
  display: flex;
  justify-content: space-between;
  padding: 3rem 0;

  // Mobile styles
  // @include d-desktop {
  //   padding: 3rem 9rem;
  // }
  // @include mid-991-tab {
  //   margin: 3rem 5rem;
  // }
  // @include x-tablet {
  //   padding: 0 3rem;
  // }
  @include mobile {
    display: inline-block;
  }

  .text-heading {
    font-size: 24px;
    font-weight: bold;
    font-family: 'Work Sans';

    @include mid-991-tab {
      font-size: 2rem;
    }
    @include x-tablet {
      font-size: 1.8rem;
      margin-bottom: 1rem;
    }
    @include mobile {
      font-size: 1.8rem;
      margin-bottom: 1rem;
    }
  }

  .dropdown {
    font-size: 20px;
    font-weight: normal;
    color: #8c8c8c;
    font-family: 'Work Sans';
    display: flex;
    gap: 1rem;

    @include x-tablet {
      font-size: 1.8rem;
    }
    @include mobile {
      font-size: 1.8rem;
    }

    select {
      font-size: 20px;
      color: #8c8c8c;
      width: 145px;
      height: 42px;
      border-color: #8c8c8c;
      border-radius: 22px;
      color: #1D7336;

      @include x-tablet {
        width: 120px;
        height: 35px;
        font-size: 1.8rem;
      }
    }

    option {
      font-size: 1.8rem;
      color: #8c8c8c;
      font-family: 'Work Sans';
      text-align: center;
      color: #1D7336;

      @include x-tablet {
        font-size: 1.8rem;
      }
      @include mobile {
        font-size: 1.5rem;
      }
    }
  }
}

.match-info {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
}

.team-name1,
.team-name2 {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #6c6c6c;
  white-space: nowrap;
  width: 145px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.team-vs {
  font-size: 12px;
  font-weight: 500;
  color: #6c6c6c;
  display: block;
  width: 16px;
  height: 17px;
}

.scores {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  text-align: left;
}

.team-score {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  img {
    margin-left: 10px;
  }

  &.team-left {
    flex-direction: row;
  }

  &.team-right {
    flex-direction: row-reverse;
  }
}

.team-logo1,
.team-logo2 {
  width: 42px;
  height: 42px;
  background: #ffffff;
  border-radius: 50%;
}

.team-logo1 {
    border: 0.85px solid #4764da;
    border: 0.85px solid #4764da;
    border-radius: 50%;
  border: 0.85px solid #4764da;
    border-radius: 50%;
}

.team-logo2 {
    border: 1px solid #6a0913;
    border: 1px solid #6a0913;
    border-radius: 50%;
  border: 1px solid #6a0913;
    border-radius: 50%;
}

.score-details {
  margin-left: 10px;
  text-align: left;
  width: 59px;

  .runs {
    font-family: 'Work Sans';
    font-size: 18px;
    font-weight: 600;
    line-height: 21.11px;
    letter-spacing: 0.02em;
    color: #231f20;
    width: auto;
    margin-bottom: 5px;
  }

  .overs {
    font-family: 'Work Sans';
    font-size: 16px;
    font-weight: 600;
    line-height: 18.77px;
    color: #b7b7b7;
    width: auto;
    margin-top: 5px;
  }
}

.score-vs {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  color: #231f20;
  width: 16px;
  height: 17px;
}

.stadium-info {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #ebf0ff;

  .stadium-name {
    font-family: 'Work Sans';
    font-size: 14px;
    font-weight: 500;
    line-height: 16.42px;
    color: #555353;
    width: 100%;
  }

  .live-status {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    color: #e40b21;
    margin-right: 15px;
  }
}
  
  // @media (max-width: 992px) {
  //   .filter-card-container {
  //     flex: 1 1 50%;
  //     max-width: 33.33333333%;
  //   }
  // }
  
