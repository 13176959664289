@import "../../../assets/scss/media";
.cardInner {
  // clip-path: polygon(0% 0, 100% 0, 100% 90%, 95% 100%, 0 100%, 0 19%);
  border-radius: 0.6rem;
  position: relative;
  .cardAllWrap {
    display: block;
    height: 100%;
    width: 100%;
    border-radius: 0.6rem;
    overflow: hidden;
    .imgWrap {
      width: 100%;
      height: 30rem;
      position: relative;
      &::before {
        content: "";
        width: 100%;
        height: 100%;
        background: linear-gradient(
          360deg,
          rgba(13, 83, 118, 1) 0%,
          rgba(249, 249, 250, 0.24) 100%
        );
        // background: linear-gradient(90deg, rgba(0,0,0,1) 30%, rgba(15,43,79,1) 100%);
        position: absolute;
        top: 0;
        left: 0;
      }
      @include x-767-tablet {
        height: 20rem;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .playBtn {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 5.8rem;
        height: 5.8rem;
        // border: 3px solid #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translate(-50%, -50%);
        border-radius: 50%;

        @include x-767-tablet {
          width: 4rem;
          height: 4rem;
        }
      }
    }
    .midBox {
      padding: 1rem;
      position: absolute;
      bottom: 0;
      background-color: #0f2b4f;
      border-radius: 0.6rem;
      p {
        color: #182046;
        font-size: 1.3rem;
        font-weight: 600;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        margin-bottom: 0.5rem;
      }
      h5 {
        color: #fff;
        font-size: 1.8rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        @include x-767-tablet {
          font-size: 1.8rem;
        }
        @supports (-webkit-line-clamp: 2) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: initial;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
    .footBox {
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .dateBox {
        color: #6c6c6c;
        font-size: 1.6rem;
        font-weight: 500;
        @include x-767-tablet {
          font-size: 1.2rem;
        }
      }
    }
  }
}

.shareBox {
  position: absolute;
  right: 5%;
  bottom: 2%;
  transform: translate(0, -50%);
  z-index: 1;
  @include x-767-tablet {
    bottom: 1%;
  }
}
