.ant-modal {
  width: 70%;
  max-width: 900px;
  .ant-modal-header {
    // background-color: #f5f5f5;
    padding: 20px;
    border-bottom: 2px solid #ccc;
  }
  .ant-modal-title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
  }
  .ant-modal-body {
    padding: 1rem 2rem;
    // background-color: #fafafa;
    color: #333;

    h3 {
      margin-top: 20px;
      font-size: 2rem;
      color: #0056b3;
    }

    p {
      font-size: 1.5rem;
      line-height: 2.5rem;
    }

    .youtube-links {
      margin-top: 10px;
      p {
        font-weight: bold;
      }

      a {
        display: block;
        color: #007bff;
        text-decoration: none;
        margin-top: 5px;
        transition: all 0.3s;
        &:hover {
          text-decoration: underline;
          color: #0056b3;
        }
      }
    }

    .performance-section {
      //   background-color: #e9ecef;
      border-radius: 5px;
      margin-bottom: 20px;

      h3 {
        color: #333;
      }

      p {
        font-size: 1.5rem;
      }
    }
  }

  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 1.5rem 2rem;
    // background-color: #f5f5f5;

    .ant-btn {
      padding: 8px 20px;
      font-size: 2rem;
      font-weight: 600;
      border-radius: 5px;
      &:hover {
        // color: #0a2e0c;
        border-color: #0a2e0c;
      }
    }

    .ant-btn-primary {
      background-color: #1d7336;
      &:hover {
        background-color: #0a2e0c;
      }
    }

    .ant-btn-danger {
      background-color: #dc3545;
      border-color: #dc3545;
    }
  }
}
