@import '../../assets/scss/media';
@import "../../assets/scss/mixin";
.login-form {
  background-image: url(../../../public/images/full-banner.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: auto;
  padding: 2rem 0;

  h6 {
    @include formHeadingTypo;
    padding-bottom: 1.5rem;
    @include mobile {
      font-size: 2.5rem;
    }
  }

  .form-fields {
    margin: 2rem;

    .btm-text {
      display: flex;
      justify-content: flex-end;
      margin-right: 2rem;
      .otp-btn {
        font-size: 1.5rem;
        color: #fff;
      }
    }

    .otp-txt {
      display: grid;
      justify-content: flex-end;
      padding-right: 2rem;
    }

    .form-check {
      font-size: 1.5rem;
      color: #fff;
      margin-left: -2rem;
      .form-check-label {
        margin-left: 0.5rem;
      }
    }

    .col {
      a {
        font-size: 1.5rem;
        color: #fff;
      }
    }

    .input-field {
      // width: 29%;
      .form-label {
        @include formLabelTypo;
        @include mobile {
          font-size: 1.5rem
        }
      }
      .form-control {
        @include inputTypo;
      }
      .textdanger {
        @include formErrorMsgTypo;
      }
    }

    .text-center {
      display: flex;
      justify-content: space-between;
      // gap: 6rem;
      @include x-tablet {
        display: block;
        margin-left: 7rem;
      }
      @include mobile {
        margin-left: 16rem;
      }
      a {
        @include anchotTagTypo;
      }
      a:hover {
        color: #f39c12;
        text-decoration: underline;
      }
    }
    .bottom-link {
      @include formLabelTypo;
      text-align: center;
    .paragraph {
      padding-top: 1rem;
    }
    p,
    a {
      @include anchotTagTypo;
    }
    a:hover {
      color: #f39c12;
      text-decoration: underline;
    }
  }
    .sign-in {
      margin-top: 2rem;
      button {
        @include formButtonTypo;
        width: 42%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
      }
    }
  }
}
