@import "../../../assets/scss/media";
@import "../../../assets/scss/mixin";
.bg-img {
  position: relative;
  // background-image: url(../../../../public//images/DownloadAppContainer.png);
  background: linear-gradient(
    90deg,
    rgba(2, 91, 154, 1) 0%,
    rgba(3, 49, 81, 1) 100%
  );
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  // height: 451px;
  height: auto;
  margin: 3rem 0 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @include mid-991-tab {
    padding: 1rem 0 2rem;
  }
}
.midSectionWrap {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @include mid-991-tab {
    align-items: flex-start;
  }
  @include mobile {
    display: inline-block;
  }
  .bannerContentWrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 6rem;
    width: 100%;
    @include mid-991-tab {
      margin-top: 0;
    }
  }
  .stats-details {
    display: flex;
    justify-content: space-between;

    @include mid-991-tab {
      display: flex;
      justify-content: space-evenly;
    }
  }
}

// .acheivement {
//   position: absolute;
//   left: 4.5rem;
//   top: 10%;
//   @include d-desktop {
//     // top: 4% !important;
//     top: 8% !important;
//     left: 3.5rem !important;
//   }
//   @include xu-desktop {
//     top: 11%;
//   }
//   @include mid-991-tab {
//     display: block;
//     position: absolute;
//     top: 33.3rem !important;
//     left: 11.5rem !important;
//   }
//   @include mobile {
//     left: 13rem;
//     top: 30rem !important;
//   }
//   @include xss-mobile {
//     top: 72%;
//     left: 23.5%;
//   }
//   @include xssm-mobile {
//     left: 27%;
//   }
// }

// .acheivement-btn {
//   width: 193px;
//   height: 46px;
//   border-radius: 2.2rem;
//   color: #416d3d;
//   font-size: 1.8rem;
//   font-family: "Work Sans";
//   font-weight: 600;
//   border: 1px solid #0e5623;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   @include d-desktop {
//     border-color: #fff;
//     color: #fff;
//   }
//   @include mobile {
//     width: 160px;
//     height: 42px;
//     color: #fff;
//     font-size: 1.8rem;
//   }
// }

.stats-info {
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
  width: 100%;
  @include mid-991-tab {
    margin-bottom: 0;
  }
  @include mobile {
    display: inline-block;
    margin-bottom: -13px;
  }
  h2 {
    font-family: "Work Sans";
    font-size: 3.4rem;
    font-weight: 600;
    color: #ffffff;
    @include d-desktop {
      font-size: 3.4rem;
    }
    @include mid-991-tab {
      font-size: 3.5rem;
      text-align: center;
    }
    @include mobile {
      font-size: 2.5rem;
      text-align: center;
    }
    @include xss-mobile {
      font-size: 2.2rem;
    }
  }
  p {
    font-size: 20px;
    font-weight: 500;
    color: #d7d2d2;
    width: 80%;
    margin-bottom: 1rem;
    @include d-desktop {
      width: 100%;
    }
    @include mid-991-tab {
      text-align: center;
      font-size: 1.8rem;
      color: #d7d2d2;
    }
    @include xss-mobile {
      font-size: 1.5rem;
    }
    @include mid-991-tab {
      text-align: center;
      width: 100%;
    }
  }
}

.stat-item {
  width: 171.31px;
  height: 59px;
  margin-bottom: 50px;
  @include xss-mobile {
    margin-bottom: 2rem;
  }
  h3 {
    font-family: "Work Sans";
    font-size: 24px;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 5px;
    @include mid-991-tab {
      font-size: 2.2rem;
    }
    @include mobile {
      font-size: 1.9rem;
    }
    @include xss-mobile {
      font-size: 1.6rem;
    }
  }
  p {
    font-family: "Work Sans";
    font-size: 18px;
    color: #d9d9d9;
    @include mid-991-tab {
      font-size: 1.6rem;
    }
    @include mobile {
      font-size: 1.4rem;
    }
    @include xss-mobile {
      font-size: 1.3rem;
    }
  }
}

.stats-left,
.stats-right {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.download-button {
  width: 193px;
  height: 46px;
  border-radius: 22px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  @include mobile {
    width: 160px;
    height: 42px;
  }
}

.download-button {
  color: #416d3d;
  font-size: 1.8rem;
  font-family: "Work Sans";
  font-weight: 600;
  @include mid-991-tab {
    display: none;
  }
  @include mid-991-tab {
    display: block;
    position: relative;
    bottom: -22rem;
    margin: auto;
  }
  @include x-tablet {
    width: 14.5rem;
    height: 3.5rem;
    font-size: 1.5rem;
  }
  @include xss-mobile {
    bottom: -16rem;
  }
}

.download-button img {
  width: 18px;
  height: 19px;
  color: #416d3d;
  margin-left: 0.2rem;
  @include mid-991-tab {
    width: 1.6rem;
    height: 1.6rem;
    margin-left: 0.5rem;
  }
  @include x-tablet {
    width: 1.5rem;
    height: 1.5rem;
  }
}
