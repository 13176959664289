@import "../../assets/scss/media";
@import "../../assets/scss/mixin";
.background-img {
  background-image: url(../../../public/images/full-banner.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100%;
  width: 100%;
  //   height: 43rem;
  height: auto;

  .forgetpassword-page {
    padding: 4rem;
  }

  .heading {
    h6 {
      @include formHeadingTypo;
      padding-bottom: 3.5rem;
      @include mobile {
        font-size: 2.5rem
      }
    }
  }

  .form-fields {
    .input-field {
      @include formLabelTypo;
      @include mobile {
        font-size: 1.5rem;
      }
      .form-control {
        @include inputTypo;
      }
      .textdanger {
        @include formErrorMsgTypo;
      }
    }
    .otp-timer {
      display: flex;
      justify-content: flex-end;
      // padding-right: 3rem;
    }
  }

  .form-check {
    // align-items: center;
    display: flex;
    .btm-text {
      margin-top: -3rem;
      text-align: center;
    }
    .sent-otp {
      font-size: 1.5rem;
      color: #fff;
    }
  }
  .sign-in {
    margin-top: 2rem;
    button {
      @include formButtonTypo;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1.5rem;
      width: 50%;
    }
  }
}
