@import "../../../assets/scss/media";
.heading-dropdown {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;

  @include md-mobile {
    display: block;
  }

  .heading {
    h6 {
      font-family: 'Work Sans';
      font-size: 3rem;
      font-weight: 600;
      color: #0C495B;
    }
  }

  .dropdown-videos {
    font-family: 'Work Sans';
    font-size: 2rem;
    color: #8C8C8C;

    select {
      width: 154px;
      height: 42px;
      border-radius: 21px;
      font-family: 'Work Sans';
      font-size: 2rem;
      color: #1D7336;
      text-align: center;
      margin-left: 1rem;
    }
  }
}

#highlightsSection {
  @include mobile {
    padding: 0 1.5rem;
  }
}
#VideoSlider {
  padding: 8rem 0 13rem;
  // margin-top: -10rem;
  // @include d-1366-desktop{
  //   margin-top: -5rem;
  // }
  // @include d-desktop{
  //   margin-top: -1rem;
  //   padding: 0 1rem 7rem;
  // }
  @include x-767-tablet {
    margin-top: 0;
    padding: 5rem 1rem 7rem;
  }

  .topSecWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;
    padding: 0 0.5rem;
    @include x-767-tablet {
      padding: 0 1rem;
    }
  }
  .shareBox {
    .timeText {
      display: none;
    }
  }
}
