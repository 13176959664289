@import "../../assets/scss/media";
.tournament {
  padding: 3rem 0 5rem;
  @include x-767-tablet {
    // margin: 1rem 2rem;
  }
}

.header-div {
  display: flex;
  @include mobile {
    display: inline-block;
  }
}

.search-container {
  // position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
  @include mobile {
    margin-top: 1rem;
  }

  input {
    width: 230px;
    height: 42px;
    border-radius: 22px;
    border: 1px solid #8c8c8c;
    padding-left: 20px;
    padding-right: 40px;
    font-size: 18px;
    font-family: "Work Sans";
    color: #b7b7b7;
    box-sizing: border-box;

    &::placeholder {
      font-size: 1.8rem;
      font-family: "Work Sans";
      color: #b7b7b7;
      text-align: left;
    }
  }
}

// .search-icon {
//   position: absolute;
//   right: 16%;
//   width: 19px;
//   height: 19px;
// }

.filter-icon {
  width: 26px;
  height: 26px;
}

.search-icon,
.filter-icon {
  fill: #4b803b;
  background: none;
  border: none;
  cursor: pointer;
}

.filter-content {
  background: aliceblue;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  width: 50%;
  margin: 2rem auto;

  div {
    display: grid;
    grid-template-columns: 1fr 1fr; // 2 columns
    gap: 1rem; // Adjust spacing between items
  }
}
