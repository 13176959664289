@import "../../../assets/scss/media";

.regButton {
  position: relative;
  width: 193px;
  height: 45px;
  border-radius: 20px;
  background: #ffffff;
  box-shadow: 0px 10px 20px 0px #9999991a;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  gap: 0.5rem;
  align-items: center;
  @include d-desktop {
    // height: 3.5rem;
    width: 16.5rem;
  }
  @include x-tablet {
    width: 14.5rem;
    height: 3.5rem;
  }
  @include mobile {
    height: 4rem;
    width: 15rem;
  }
  span {
    font-family: "Work Sans";
    font-size: 20px;
    font-weight: 600;
    line-height: 21.11px;
    text-align: center;
    color: #416d3d;
    margin-left: 8px;
    @include d-desktop {
      font-size: 1.8rem;
    }
    @include x-tablet {
      font-size: 1.5rem;
    }
    @include mobile {
      font-size: 1.7rem;
    }
  }
  .button-icon {
    width: 20px;
    height: 20px;
    @include x-tablet {
      width: 15px;
      height: 18px;
    }
  }
  .right-arrow {
    width: 20px;
    height: 20px;
  }
}
