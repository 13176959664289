.background-image {
    background-image: url(../../../../public/images/full-banner.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    width: 100%;
    height: auto;
  
    .profile-pageWrap {
      .profile-picture {
        width: 350px;
        height: 279px;
        max-height: fit-content;
        overflow: hidden;
        cursor: pointer;
        border: solid 0.8rem white;
        border-radius: 0.2rem;
        // transition: transform 0.3s ease, box-shadow 0.3s ease;
  
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
  
        // &:hover {
        //   transform: scale(1.05);
        //   box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        // }
      }
  
      .profile-container {
        display: flex;
        align-items: center;
        gap: 12rem;
  
        .info-score {
          margin: 2rem;
          display: flex;
          flex-direction: column;
          gap: 2rem;
  
          .user-info {
            text-align: flex-start;
  
            h1 {
              color: #f5f5f5;
              font-size: 3rem;
              margin-bottom: 1rem;
            }
  
            p {
              color: #f5f5f5;
              font-size: 1.8rem;
              margin-bottom: 0.2rem;
            }
          }
        }
  
        .score-container {
          .score-list {
            display: flex;
            gap: 1rem;
            list-style: none;
            padding: 0;
            margin: 0;
            width: 100%;
  
            .score-item {
              background-color: #025b9a;
              padding: 1.5rem 2.5rem;
              border-radius: 8px;
              text-align: center;
              color: #fff;
              transition: transform 0.3s ease, box-shadow 0.3s ease;
  
              &:hover {
                transform: scale(1.05);
                box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
              }
  
              .score-number {
                font-size: 3.5rem;
                font-weight: bold;
              }
  
              .score-label {
                font-size: 1.5rem;
                margin-top: 0.5rem;
                letter-spacing: 1px;
                text-transform: uppercase;
              }
            }
          }
        }
      }
    }
    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        overflow: auto;
  
      .modal-content {
        max-width: 50%;
        max-height: 90%;
        padding: 10px;
        overflow: hidden;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  
        img {
          width: 100%;
          height: auto;
          object-fit: contain;
          border-radius: 8px;
        }
      }
    }
  }
  