@import "../../../assets/scss/media";

.navBarSection {
  width: 100%;
  z-index: 1000;
  // background-color: rgb(10 56 4 / 80%);
  background-color: #025B9A;
  display: block;
  position: sticky;
  top: 17rem;
  transition: top 0.8s;
  &.topClass {
    // background-color: transparent;
    background-color: #025B9A;
  }
}

.navbar {
  width: 100%;
  .navbar-toggler {
    position: absolute;
    right: 12px;
    width: 5.1rem;
    height: 4rem;
    color: #fff;
  }

  .navWrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logoWrap {
      img {
        @include xssm_360_mobile {
          width: 200px;
        }
      }
    }

    .menu {
      @include d-1366-desktop {
        width: 60%;
      }
    }

    .navbar-collapse {
      justify-content: flex-end;

      @include mid-991-tab {
        // background: linear-gradient(90deg, rgb(58, 98, 62) 19%, rgb(84, 143, 57) 100%);
        background: linear-gradient(
          90deg,
          rgba(2, 91, 154, 1) 0%,
          rgba(3, 49, 81, 1) 100%
        );
        display: block;
        height: 100%;
        left: -100%;
        overflow-y: auto;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 30px;
        position: fixed;
        top: 0;
        transition: all 0.2s cubic-bezier(0, 0, 0.2, 1) 0.1s !important;
        width: 50%;
        z-index: 999999;
        width: 60%;
        &.show {
          left: 0;
        }
      }

      .navbar-nav {
        gap: 2rem;
        @include mid-991-tab {
          .nav-item {
            justify-content: center;
            flex-direction: column;
            align-items: center;
          }
          .dropdown-item {
            font-size: 1.5rem;
            text-align: center;
          }
        }
      }

      .nav-link {
        font-size: 1.5rem;
        font-weight: 500;
        color: #fff;
        @include mid-991-tab {
          display: flex;
          justify-content: center;
          align-items: center;
          .col-md-6 {
            flex: 0 0 auto;
            width: 60%;
          }
        }
      }
    }
  }
}

.leftBox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;

  @include d-1366-desktop {
    gap: 1rem;
  }
  @include d-desktop {
    gap: 0.4rem;
  }

  // .searchWrap {
  //   // width: 4.1rem;
  //   // height: 4.1rem;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   // border: 0.1rem solid #b7b7b7;
  //   border-radius: 5rem;

  //   @include d-1366-desktop {
  //     width: 7rem;
  //     height: 7rem;
  //     img {
  //       width: 50%;
  //     }
  //   }
  //   @include d-desktop {
  //     width: 7rem;
  //     height: 7rem;
  //     img {
  //       width: 50%;
  //     }
  //   }
  // }

  .register-btn {
    background-color: #FFFFFF;
    height: 39px;
    width: 115px;
    font-size: 1.8rem;
    font-weight: 600;
    color: #075d9a;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border-radius: 5rem;
    padding: 0;
    border: 0;

    &:hover {
      color: black;
    }

    @include d-desktop {
      font-size: 1.2rem;
      height: 3.5rem;
      width: 10rem;
      span {
        img {
          height: 1.4rem;
        }
      }
    }
    @include mid-991-tab {
      display: none;
    }
  }

  .iconWrap {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      @include d-1366-desktop {
        width: 85%;
      }
      @include d-desktop {
        width: 70%;
      }
    }
  }
}
.deskBox {
  @include mid-991-tab {
    .searchWrap {
      position: absolute;
      right: 6rem;
      @include mobile {
        right: 4.5rem;
      }
    }
    .iconWrap {
      display: none;
    }
    .btn {
      display: none;
    }
  }
}

.mobBox {
  @include min-tab {
    display: none;
  }
  @include mid-991-tab {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 3.5rem;
    margin: 6%;
  }
  @include mobile {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin: 6%;
  }

  @include mid-991-tab {
    .searchWrap {
      img {
        display: none;
      }
    }
    .iconWrap {
      img {
        width: 3.5rem;
        height: 3.5rem;
        @include mobile {
          width: 3rem;
          height: 3rem;
        }
      }
    }
  }
  .btn {
    cursor: pointer;
    @include mid-991-tab {
      width: 40%;
      border: 2px solid #fff;
      font-size: 2.5rem;
      border-radius: 22px;
      color: #fff;
      font-family: "Work Sans";
      font-weight: 500;
      @include x-tablet {
        font-size: 2rem;
      }
      @include mobile {
        width: 60%;
        font-size: 1.9rem;
      }
    }
    img {
      @include mid-991-tab {
        width: 1.5rem;
        height: 6rem;
        @include mobile {
          width: 1.2rem;
          height: 3.5rem;
        }
      }
    }
  }
}

// Hamburger menu
.menu-btn-1 {
  height: 4px;
  width: 34px;
  cursor: pointer;
  position: absolute;
  right: 2rem;
  margin-top: 0px;
  display: none;
  @include mid-991-tab {
    display: block;
  }

  span,
  span::before,
  span::after {
    background: #fff;
    border-radius: 3px;
    content: "";
    position: absolute;
    width: 34px;
    height: 5px;
    margin-top: 0;
    transition: 0.3s ease-in-out;
  }

  span::before {
    margin-top: -12px;
  }

  span::after {
    margin-top: 12px;
  }

  &.active span {
    background: transparent;
  }

  &.active span::before {
    margin-top: 0;
    transform: rotate(45deg);
  }

  &.active span::after {
    margin-top: 0;
    transform: rotate(-45deg);
  }
}
