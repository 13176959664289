@import "../../assets/scss/media";
@import "../../assets/scss/mixin";
.Reg-form {
  background-image: url(../../../public/images/full-banner.png);
  background-repeat: no-repeat;
  background-size: cover;
  // background-position: 100%;
  background-position: center;
  width: 100%;
  height: 100%;
  // border-radius: 5px;
  margin: auto;

  .heading {
    padding: 2rem;
    h6 {
      @include formHeadingTypo;
      padding: 2rem;
      @include mobile {
        font-size: 2.5rem;
      }
    }
  }

  .form-fields {
    .lableIconWrap {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 0.3rem;
    }

    .form-control,
    .form-select {
      @include inputTypo;
      color: black;
      &.no-height {
        height: auto;
      }
      &:focus {
        outline: none;
        border: 1px solid #888;
      }
    
      // &:valid {
      //   color: black;
      // }

      @include mid-991-tab {
        height: 40px;
        margin-bottom: 4px;
      }
    }

    label {
      @include formLabelTypo;
      @include mobile {
        font-size: 1.5rem;
      }
    }
    // label::after{
    //   content: " * ";
    // }
  

    select,
    option {
      color: black;
    }
    ::placeholder {
      @include formPlaceholderTypo;
    }

    // option:disabled {
    //   color: #a7a3a3; // Placeholder color
    // }

    .form-select:disabled {
      background-color: #c2c0c0;
      color: #888;
      cursor: not-allowed;
    }

    .errorMsg {
      @include formErrorMsgTypo;
    }

    .text-center {
      .paragraph {
        padding-bottom: 4.5rem;
        padding-top: 1rem;
      }
      p,
      a {
        @include anchotTagTypo;
      }
      a:hover {
        color: #f39c12;
        text-decoration: underline;
      }
    }
  }

  .reg-btn {
    padding-top: 2rem;
    button {
      @include formButtonTypo;
      width: 18%;
    }
    :hover {
      background-color: #36426c;
    }
  }
}
