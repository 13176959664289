@import '../../../assets/scss/media';
@import "../../../assets/scss/mixin";
.top-Stories {
    padding: 3rem 0 0;

.container {
  display: grid;
  grid-template-columns: 1fr 300px;
  padding: 20px;
  grid-column-gap: 20px;

  @include d-desktop {
    display: block
  }

  .stories {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border: 1px solid #ddd;
    border-radius: 5px;

    @include x-tablet {
        display: block
      }

    .header {
      grid-column: 1 / -1;
      display: flex;
      justify-content: space-between;
      align-items: center;
    //   margin-bottom: 20px;
      border-bottom: 1px solid #ddd;
      padding: 2rem;

      h2 {
        @include subTitleTypo;
      }

      a {
        font-family: "Exo 2";
        font-size: 2rem;
        font-weight: bold;
        color: #1d7336;
        text-decoration: none;
      }
    }

    .story {
      display: flex;
      gap: 10px;
      border: 1px solid #ddd;
      // border-bottom: 1px solid #ddd;
      // border-left: 1px solid #ddd;
      padding: 10px;

      img {
        width: 180px;
        height: 150px;
        object-fit: cover;
        border-radius: 5px;
        @include x-tablet {
            width: 300px;
          }
        @include md-mobile {
            width: 180px;
        }
      }

      .story-content {
        a {
          font-family: "Work Sans";
          font-size: 1.6rem;
          font-weight: 600;
          color: #1e1e1e;
        }

        p {
          font-family: "Work Sans";
          font-size: 1.4rem;
          color: #6c6c6c;
          margin-top: 2rem;
          @include mid-991-tab {
            margin-top: 2rem;
          }
        }
      }
    }
  }

  .ads {
    display: grid;
    gap: 2rem;

    @include d-desktop {
        display: flex;
        justify-content: center;
        margin-top: 2rem;
      }

    .ad {
      img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        margin-bottom: 20px; // Adds space between ads
        border: 1px solid #c9c9c9;
        padding: 1rem;
      }
    }
  }
}
}

