@import "../../../assets/scss/media";

  .tenniscricket {
    text-align: center;
    padding: 12px;

    h2 {
      text-align: center;
      padding: 20px;
      font-family: "Work Sans";
      font-size: 3.4rem;
      color: #0C495B;
    @include mobile {
        font-size: 2.5rem;
    }
  }
}

  .tab {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    position: relative;
    @include mobile {
        margin-bottom: 4rem;
      }

    button {
      margin: 0 15px;
      text-decoration: none;
      color: #898989;
      font-size: 18px;
      padding-bottom: 5px;
      border-bottom: 2px solid transparent;
      border-bottom-color: #d9d9d9;
      @include mobile {
        font-size: 1.6rem;
      }

      &.active {
        color: #23313A;
        border-bottom-color: #0e5623;
      }
    }
  }

  .phone {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 20px;

    img {
      max-width: 100%;
      border-radius: 30px;
    }
  }

  .toggle {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;

    input {
      display: none;
    }

    .slide {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      transition: 0.4s;
      border-radius: 20px;

      &::before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 4px;
        bottom: 2px;
        background-color: white;
        transition: 0.4s;
        border-radius: 50%;
      }
    }

    input:checked + .slider {
      background-color: #4caf50;
    }

    input:checked + .slider::before {
      transform: translateX(20px);
    }
  }

  .p-content {
    position: absolute;
    font-family: "Work Sans", sans-serif;
    font-size: 18px;
    color: white;
    text-align: center;
    font-weight: bold;
    bottom: 20%;
  }

  // Show feature section styles below 576px
  @media (max-width: 576px) {
    .cards {
      display: flex;
      overflow-x: auto;
      flex-wrap: nowrap;
      justify-content: flex-start;

      .card {
        border-radius: 10px;
        padding: 2rem;
        width: 50%;
        max-width: 300px;
        height: 50%;
        margin: 1rem;
        text-align: center;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        background: #d4ffe8;
        flex-shrink: 0;

        .icon {
          font-size: 40px;
          margin-bottom: 10px;
        }

        h3 {
          margin-bottom: 5px;
          font-family: "Work Sans";
          font-size: 22px;
          font-weight: 500;
          line-height: 25.81px;
          color: #025B9A;
        }

        p {
          margin-bottom: 20px;
          font-family: "Work Sans";
          font-size: 1.5rem;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0.01em;
          color: #898989;
        }
      }

      // Custom scrollbar styles
      &::-webkit-scrollbar {
        height: 10px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #416D3D;
      }
    }

    .cards::-webkit-scrollbar {
      height: 8px;
    }
  }

  

